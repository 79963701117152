body {
  background-color: #524b4b;
  box-sizing: border-box;
}
ul {
  list-style: none;
  padding-left: 0px;
}
.section {
  margin-bottom: 100px;
}
